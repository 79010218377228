import React from 'react';
import { render } from 'react-dom';
import './index.css';
import { Config } from './types/global';
import config from './reapit.config.json';

// Init global config
window.reapit = {
  config: {
    appEnv: '',
    connectClientId: '',
    connectOAuthUrl: '',
    connectUserPoolId: '',
    platformApiUrl: '',
    marketplaceUrl: '',
    backOfficeToken: '',
    backOfficeURL: ''
  }
};

export const renderApp = (Component: any) => {
  const rootElement = document.querySelector('#root') || document.body;
  const isDesktop = Boolean(window['__REAPIT_MARKETPLACE_GLOBALS__']);
  const html = document.querySelector('html');
  if (isDesktop && html) {
    html.classList.add('is-desktop');
  }

  if (rootElement) {
    render(<Component />, rootElement);
  }
};

const run = async () => {
  try {
    const globalConfig: Config = config;

    // Customise the global config from ENVIRONMENT VARIABLES
    globalConfig.connectClientId = process.env.REACT_APP_REAPIT_CLIENT_ID ?? '';
    globalConfig.connectOAuthUrl = process.env.REACT_APP_CONNECT_OAUTH_URL ?? '';
    globalConfig.connectUserPoolId = process.env.REACT_APP_REAPIT_CONNECT_USER_POOL_ID ?? '';

    // Set the global config
    window.reapit.config = globalConfig;

    console.log(window.reapit.config);

    // I import the app dynamically so that the config is set on window and I avoid any
    // runtime issues where config is undefined
    const { default: App } = await import('./core/app');

    renderApp(App);
  } catch (error) {
    console.error('Cannot fetch config', error);
  }
};

run();
